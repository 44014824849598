/* Inventory.css */

.inventory-container {
  padding: 20px;
  max-width: 1600px;
  margin: 0 auto;
  color: white;
}

.inventory-title {
  color: #e0e0e0;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.inventory-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.2rem;
  background-color: #00bcd4;
  border-radius: 0.2rem;
}

.inventory-filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .inventory-filter-bar {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

.inventory-filter-form,
.inventory-sort-form {
  min-width: 180px; /* Increased min-width for filters and sorts */
  margin-right: 20px;
}

@media (max-width: 768px) {
  .inventory-filter-form,
  .inventory-sort-form {
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
  }
}

.inventory-filter-label,
.inventory-sort-label {
  color: #bdbdbd !important;
}

.inventory-select {
  color: white !important;
  background-color: #333;
  border-radius: 8px;

  & .MuiOutlinedInput-notchedOutline {
    border-color: #555;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #777;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #00bcd4;
    border-width: 1px;
  }

  & .MuiSelect-icon {
    color: #fff;
  }
}

.inventory-grid-container {
  overflow-y: auto;
  max-height: 60vh;
  padding: 20px;
  background-color: rgba(25, 25, 25, 0.8);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}
