#header {
    position: fixed;
    /* width: 240px; */
    z-index: 10;
  }
  #mttcontainer {
    color: white;
  }
  #header .pro-sidebar {
    height: 100vh;
  }
  #header .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    /* font-weight: bold; */
    font-size: 22px;
    top: 55px;
    cursor: pointer;
  }
  #header .pro-sidebar {
    width: 240px;
    min-width: 100%;
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    /* main background color; */
    background-color: #102648;
    /* box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15); */
    border-right: 2px solid rgba(133, 133, 133, 0.1);
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    /* font-weight: bold; */
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #000;
    margin: 10px 0px;
    border-bottom: 1px solid #00ff751a;;
    padding-left: 16px;
    /* font-weight: bold; */
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #102648;
    color: #000;
    border-radius: 3px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
  }
  #header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    background-color: white;
  }
  .pro-sidebar-inner .pro-sidebar-layout .active .select{
    display: block;
  }
  #outlined-number-label {
    color: white;
  }
  #outlined-number {
    color: white;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    background-color:#222846;
    border-radius: 20px!important;
  }
  
  #header .pro-sidebar-inner .pro-sidebar-layout .active .pro-item-content{
    /* background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%); */
    font-style: normal;
    font-size: 16px;
    line-height: 20px;

    color: #0E7CE2;
  }
  #header .pro-sidebar-footer {
    height: 38px;
  }
  #header .pro-sidebar-footer .pro-menu {
    margin: 0px;
    padding: 0px;
  }
  #header .pro-sidebar-footer .pro-menu .pro-inner-item {
    margin: 0px !important;
    padding: 0px;
  }
  #header .pro-sidebar-content .pro-menu-item .popper-inner {
    background-color: white;
  }
  #header .logo {
    padding: 20px;
  }
  