header.MuiPaper-root {
    /* background-color: #0e0e3038; */
    /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px #0e0e30, 0px 1px 10px 0px rgb(0 0 0 / 12%); */
    background: none;
    margin: auto;
}

@media (max-width: 900px) {
    header.MuiPaper-root {
        /* background-color: #0e0e3038; */
    }
}

header {
    /* background: red; */
}

@media (max-width: 600px) {
    .navigation-bar .MuiToolbar-root div p {
        font-size: 16px;
    }
}

.header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.header-bg-pc {
    display: block;
    height: 130px;
}

.header-bg-mobile {
    display: none;
    height: 80px;


}

@media (max-width: 1000px) {
    .header-bg-pc {
        display: none;
    }

    .header-bg-mobile {
        display: block;
    }
}